export class Song {
    constructor(data) {
        Object.assign(this, data);
        this.options = !!this.options && typeof this.options === 'object' ? this.options : {};
    }

    getOptions() {
        return this.options;
    }

    getOption(name) {
        return this.getOptions()[name];
    }

    needHumanize() {
        const humanize = this.getOption('humanize');
        return typeof humanize === 'boolean' ? humanize : true;
    }

    needShowPlayableNotes() {
        const showPlayableNotes = this.getOption('showPlayableNotes');
        return typeof showPlayableNotes === 'boolean' ? showPlayableNotes : false;
    }

    needShowMutedNotes() {
        const showMutedNotes = this.getOption('showMutedNotes');
        return typeof showMutedNotes === 'boolean' ? showMutedNotes : true;
    }

    getChordsLine() {
        return this.getOption('chordsLine');
    }
}
