import React from 'react';
import styled from 'styled-components';
import { List } from 'antd';
import Link from 'next/link';

const SongsContainer = styled.ul`
    padding: 0;
    margin: 0;
`;

const SongsListItem = styled.li`
    list-style: none;
    margin-left: 0;
    padding: 16px 32px 16px 0;
    float: left;
    width: 25%;
    min-height: 140px;
    @media (max-width: 768px) {
        width: auto;
        float: none;
        min-height: auto;
        padding: 8px 0 8px 0;
        border-bottom: 1px solid #fafafa;
    }
`;

const SongsBlockItem = styled.li`
    list-style: none;
    margin-left: 0;
    padding-bottom: 16px;
`;

const SongAuthor = styled.span`
    display: block;
    font-weight: bold;
    font-size: 16px;
    color: #555;
`;

const EmptyListMessage = styled.span`
    display: block;
    padding: 16px 0;
    font-size: 16px;
    color: #777;
    text-align: center;
`;

/**
 * @todo Now SongLink rerenders whole page because of that or smth
 * https://github.com/zeit/next-plugins/issues/282#issuecomment-432127816
 */
const SongLink = styled.a`
    display: block;
    line-height: 24px;
    font-size: 20px;
`;

export function FullSongsList({ songs, renderIcons }) {
    return <SongsContainer>
        {Array.isArray(songs) && <List
            grid={{
                gutter: 16,
                xs: 16,
                sm: 16,
                md: 4,
                lg: 4,
                xl: 6,
                xxl: 3
            }}
            style={{
                margin: '8px'
            }}
            locale={{ emptyText: <EmptyListMessage>Ничего не найдено(</EmptyListMessage> }}
            dataSource={songs}
            renderItem={song => (
                <SongsListItem>
                    <SongLink href={`/songs/${song.code}`}>{song.title}</SongLink>
                    <SongAuthor>{song.author ? song.author : 'Народная'}</SongAuthor>
                    {renderIcons(song)}
                </SongsListItem>
            )}/>}
    </SongsContainer>
}

export function BlockSongsList({ songs, renderIcons }) {
    return <SongsContainer>
        {Array.isArray(songs) && songs.map((song, key) => {
            return <SongsBlockItem key={`song-${key}`}>
                <Link href={`/songs/[song]`} as={`/songs/${song.code}`}>
                    <SongLink>{song.title}</SongLink>
                </Link>
                <SongAuthor>{song.author ? song.author : 'Народная'}</SongAuthor>
                {renderIcons(song)}
            </SongsBlockItem>
        })}
    </SongsContainer>
}
