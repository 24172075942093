import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Icon, Spin, Tooltip } from 'antd';
import SongRepository from '../service/SongRepository';
import { Rollbar } from '../context';

const IconsContainer = styled.div`
    position: relative;
    height: 30px;
    overflow: hidden;
`;

const TabsImg = styled.img`
    height: 26px;
    position: absolute;
    top: 0px;
`;
const TabsIcon = () => {
    return <TabsImg src='/images/tabs.svg' style={{ width: '26px' }} alt='Есть табы'/>
};

export default function SongsList({ filter = [], sorting = {}, pagination = {}, songs: preLoadedSongs, render }) {
    const [message, setMessage] = useState(null);
    const [songs, setSongs] = useState(preLoadedSongs);
    const [loading, setLoading] = useState(false);

    const rollbar = useContext(Rollbar.Context);

    useEffect(() => {
        const load = async () => {
            setLoading(true);
            try {
                setSongs(await SongRepository.getSongsByFilters(filter, sorting, pagination));
            } catch (e) {
                rollbar.error(e);
                setMessage('Не удалось загрузить песни');
            } finally {
                setLoading(false);
            }
        };

        if (!Array.isArray(preLoadedSongs)) {
            load();
        }
    }, [filter, pagination, sorting, preLoadedSongs]);

    const renderIcons = ({ video, hasTabs, code }) => {
        if (!video && !hasTabs) {
            return null;
        }

        return <IconsContainer>
            {video && <a href={`/songs/${code}#video`}>
                <Tooltip title='Есть видео' placement='left'><Icon style={{ color: '#820014', fontSize: '26px', marginRight: '5px' }} type='youtube'/></Tooltip>
            </a>
            }
            {hasTabs && <a href={`/songs/${code}#tabs`}>
                <Tooltip title='Есть табы' placement='right'><span><TabsIcon/></span></Tooltip>
            </a>
            }
        </IconsContainer>
    };

    return <Spin delay={100} tip={'загружаем песни...'} spinning={loading}>
        {message && <span className='error-message'>{message}</span>}
        {render({ songs, renderIcons })}
    </Spin>
}
