import axios from 'axios';
import config from '../config/app';
import { Song } from '../models/Song';

const buildFilter = (filter) => filter.reduce((memo, key) => ({ ...memo, [key]: true }), {});

const request = async (options, errorMessage) => {
    const response = await axios(options);

    if (response.headers['content-type'].indexOf('application/json') < 0) {
        throw new Error(errorMessage);
    }

    if (response.status !== 200) {
        throw new Error('Unexpected response status code');
    }

    return response.data;
};

const prepareHost = (host = '') => {
    if (host && host.indexOf('http') !== 0) {
        host = `http://${host}`;
    }

    return host;
};

export default class SongRepository {
    static async getSongsByFilters(filters, sorting = {}, pagination = {}, host = '') {
        return this.getSongs(buildFilter(filters), sorting, pagination, host);
    }

    static async getSongs(filter = {}, sorting = {}, pagination = {}, host = '') {
        return request(
            {
                method: 'GET',
                url: `${prepareHost(host)}${config.apiUrl}/song`,
                params: {
                    filter: JSON.stringify(filter),
                    sorting: JSON.stringify(sorting),
                    pagination: JSON.stringify(pagination),
                },
                responseType: 'json'
            },
            'Bad server songs response'
        );
    }

    static async getSong(code, host = '') {
        return request(
            {
                method: 'GET',
                url: `${prepareHost(host)}${config.apiUrl}/song/${code}`,
                responseType: 'json'
            },
            'Bad server song response'
        );
    }

    static async getTab(code, host = '') {
        return new Song(await request(
            {
                method: 'GET',
                url: `${prepareHost(host)}${config.apiUrl}/tab/${code}`,
                responseType: 'json'
            },
            'Bad server tab response'
        ));
    }
}
